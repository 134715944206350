import gsap from 'gsap';
import {ElementAnimation} from './element.animation';
import { AnimationTarget } from './intersection-animator';

export class OpacityReveal extends ElementAnimation implements AnimationTarget {
    inView = false;

    constructor(element: HTMLElement, index: number) {
        super(element, index, 'opacity');
    }

    in() {
        if (this.inView) return;

        this.inView = true;

        gsap.fromTo(this.element, {
            opacity: 0
        }, {
            opacity: 1,
            duration: this.duration,
            delay: this.delay,
        });
    }

    out() {}

    setIn() {
        this.inView = true;
        gsap.set(this.element, { opacity: 1 });
    }

    setOut() {
        this.inView = false;
        gsap.set(this.element, { opacity: 0 });
    }
}
