
import '../scss/index.scss';

import * as Setup from './setup';

const isTablet = window.innerWidth < 960;
const isMobile = window.innerWidth < 768;
const isDesktop = !isTablet && !isMobile;

Setup.setupGsap();
Setup.setupAnimator();
Setup.setupThemeSwitcher();
Setup.setupTextButtons();
Setup.setupScrollPosition();
Setup.setupScrollButtons();
Setup.setupSphere();
Setup.setupIotAnimation();
Setup.setupContactForm();

if (isMobile || isTablet) {
    Setup.setupNavigation();
}

if (isDesktop) {
    Setup.setupCursor();
    Setup.setupMagneticElements();
    Setup.setupServiceList();
    Setup.setupElasticLines();
    Setup.setupContactBanner();
}

Setup.setupBarba();
