import { Animatable } from './animatable';

export class Adapter extends Animatable {
    private cb: (done: () => void) => void;
    private listener: () => void;

    constructor(cb: (done: () => void) => void) {
        super();
        this.cb = cb;
    }

    play() {
        this.cb(this.listener);
    }

    pause() {}

    onBegin() {}

    onEnd(listener: () => void) {
        this.listener = listener;
    }
}
