export const lightTheme = {
    'background': '#EEEEEE',
    'heading': 'rgba(0, 0, 0, 1)',
    'paragraph': 'rgba(0, 0, 0, 0.87)',
    'decoration': 'rgba(0, 0, 0, 0.87)',
    'background-invert': 'rgba(0, 0, 0, 1)',
    'heading-invert': 'rgba(255, 255, 255, 1)',
    'paragraph-invert': 'rgba(255, 255, 255, 0.7)',
    'decoration-invert': 'rgba(255, 255, 255, 0.7)',
    'blend': '#EEEEEE'
};

export const darkTheme = {
    'background': '#000000',
    'heading': 'rgba(255, 255, 255, 1)',
    'paragraph': 'rgba(255, 255, 255, 0.7)',
    'decoration': 'rgba(255, 255, 255, 0.7)',
    'background-invert': '#EEEEEE',
    'heading-invert': 'rgba(0, 0, 0, 1)',
    'paragraph-invert': 'rgba(0, 0, 0, 0.87)',
    'decoration-invert': 'rgba(0, 0, 0, 0.87)',
    'blend': 'rgba(255, 255, 255, 1)'
};
