export type Theme = {
    'background': string,
    'heading': string,
    'paragraph': string,
    'decoration': string,
    'background-invert': string,
    'heading-invert': string,
    'paragraph-invert': string,
    'decoration-invert': string,
    'blend': string
};

export class ThemeSwitcher {
    private themes: Record<string, Theme> = {};
    private activeTheme: string;

    constructor(selector?: string, theme?: string) {
        selector && document.querySelector(selector)
            .addEventListener('click', () => {
                if (this.activeTheme === 'light') {
                    this.setTheme('dark');
                }
                else {
                    this.setTheme('light');
                }
            });
        this.activeTheme = theme;
    }

    addTheme(name: string, theme: Theme) {
        this.themes[name] = theme;
    }

    setTheme(name: string) {
        this.activeTheme = name;
        Object.entries(this.themes[name]).forEach(([key, value]) => {
            document.documentElement.style.setProperty(`--color-${key}`, value);
        });
    }

    get theme() {
        return this.activeTheme;
    }
}
