import { AnimationTimeline } from './types';
import { Sequence } from './sequence.animation';

export class Builder {
    private static _instance?: Builder;
    private _timeline: AnimationTimeline;
    private _from: string[];
    private _to: string[];
    private _object?: string[];
    private _delay?: Record<string, number[]> = {};

    static from(...from: string[]) {
        if (!this._instance) this._instance = new Builder();
        this._instance._from = from;
        return Builder;
    }
    
    static to(...to: string[]) {
        if (!this._instance) this._instance = new Builder();
        this._instance._to = to;
        return Builder;
    }

    static object(...object: string[]) {
        if (!this._instance) this._instance = new Builder();
        this._instance._object = object;
        return Builder;
    }

    static delay(when: string, ...delay: number[]) {
        if (!this._instance) this._instance = new Builder();
        this._instance._delay[when] = delay;
        return Builder;
    }

    static timeline(timeline: AnimationTimeline) {
        if (!this._instance) this._instance = new Builder();
        this._instance._timeline = timeline;
        return Builder;
    }

    static create() {
        const ids = [];
        let delay = undefined;
        this._instance._from.forEach((from: string, index: number) => {
            if (this._instance._delay['from']) {
                delay = this._instance._delay['from'][index];
            }
            this._instance._to.forEach((to: string, index: number) => {
                if (this._instance._delay['to']) {
                    delay = this._instance._delay['to'][index];
                }
                if (this._instance._object) {
                    this._instance._object.forEach((object: string, index: number) => {
                        if (this._instance._delay['object']) {
                            delay = this._instance._delay['object'][index];
                        }
                        ids.push({ 
                            id: `#Flow_${from}Tx${to}_${object}`, 
                            delay
                        });
                    });
                }
                else {
                    ids.push({ 
                        id: `#Flow_${from}Tx${to}`,
                        delay
                    });
                }
            });
        });
        const seq = new Sequence(this._instance._timeline, ...ids);
        this._instance = undefined;
        return seq;
    }
}
