import gsap from 'gsap';
import {Magnetic} from './magnetic';

export class ServiceList {
    //service list items
    private items: {
        element: HTMLElement,
        hover: HTMLElement,
        wrapper: HTMLElement,
        iconInner: HTMLElement,
        iconBackground: HTMLElement,
        magnetic: Magnetic,
        rect: DOMRect,
        mouseEnterHandler: (event: MouseEvent) => void,
        mouseLeaveHandler: (event: MouseEvent) => void
    }[] = [];
    //index of last element hovered over
    private last = -1;

    //init list items
    init() {
        const elements = document.querySelectorAll('.service-list-item');
        elements.forEach((element: HTMLElement, index: number) => {
            this.items[index] = this.createListItem(element, index);
        });
    }

    //teardown list
    destroy() {
        this.items.forEach((item) => {
            item.element.removeEventListener('mouseenter', item.mouseEnterHandler);
            item.element.removeEventListener('mouseleave', item.mouseLeaveHandler);
            item.magnetic.destroy();
        });
    }

    //create a service list item
    private createListItem(element: HTMLElement, index: number) {
        const hover = element.querySelector('.service-list-item-hover') as HTMLElement;
        const wrapper = element.querySelector('.service-list-item-hover-inner-wrapper') as HTMLElement;
        const icon = element.querySelector('.service-list-item-hover-icon') as HTMLElement;
        const iconInner = element.querySelector('.service-list-item-hover-icon-inner') as HTMLElement;
        const iconBackground = element.querySelector('.service-list-item-hover-icon-background') as HTMLElement;
        const rect = element.getBoundingClientRect();

        const magnetic = new Magnetic(icon, iconInner, iconBackground);
        magnetic.multiplyTriggerDistance(3);
        magnetic.init(false);

        const mouseEnterHandler = ((event: MouseEvent) => this.onMouseEnter(event, index)).bind(this);
        const mouseLeaveHandler = ((event: MouseEvent) => this.onMouseLeave(event, index)).bind(this);

        const item = {
            element,
            hover,
            wrapper,
            iconInner,
            iconBackground,
            magnetic,
            rect,
            mouseEnterHandler,
            mouseLeaveHandler
        };

        element.addEventListener('mouseenter', mouseEnterHandler);
        element.addEventListener('mouseleave', mouseLeaveHandler);

        return item;
    }

    //handle mouse enter
    private onMouseEnter(event: MouseEvent, index: number) {
        const edge = index > this.last ? 'top' : 'bottom';

        this.last = index;

        gsap.set(this.items[index].hover, { y: edge === 'top' ? '-101%' : '101%' });

        gsap.to(this.items[index].hover, {
            y: '0',
            duration: 0.6,
            ease: 'expo'
        });

        gsap.to(this.items[index].iconBackground, {
            scale: '1',
            duration: 0.4,
            delay: 0.2,
            ease: 'back',
            onComplete: () => this.items[index].magnetic.startRender()
        });
    }

    //handle mouse leave
    private onMouseLeave(event: MouseEvent, index: number) {
        const edge = this.determineDirection(event, index);

        this.items[index].magnetic.reset();

        gsap.killTweensOf(this.items[index].hover);
        gsap.killTweensOf(this.items[index].iconBackground);
        gsap.killTweensOf(this.items[index].iconInner);

        gsap.to(this.items[index].hover, {
            y: edge === 'top' ? '-101%' : '101%',
            duration: 0.6,
            ease: 'expo'
        });

        gsap.set(this.items[index].iconBackground, {
            scale: 0,
        });
    }

    //determine mouse enter direction
    private determineDirection(event: MouseEvent, index: number) {
        const height = this.items[index].element.offsetHeight;
        const middle = this.items[index].rect.top + height / 2;

        return event.pageY < middle ? 'top' : 'bottom';
    }
}
