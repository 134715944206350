import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import SplitType from 'split-type';

export class Navigation {
    private nav: HTMLElement;
    private menu: HTMLElement;
    private btn: HTMLElement;
    private eye: HTMLElement;
    private links: HTMLElement[];
    private linkChars: HTMLElement[][];
    private isOpen = false;

    constructor() {
        this.nav = document.querySelector('.nav');
        this.menu = document.querySelector('.nav-menu');
        this.btn = document.querySelector('.nav-toggle');
        this.eye = document.querySelector('.nav-eye');

        this.links = [
            ...Array.from(document.querySelectorAll('.nav-link')) as HTMLElement[],
            ...Array.from(document.querySelectorAll('.nav-secondary')) as HTMLElement[]
        ];
    }

    init() {
        if (!this.isTablet) {
            return;
        }
        this.wrapLinks();
        this.splitLinks();
        this.setClose();
        this.btn.addEventListener('click', () => this.toggle());
    }

    toggle() {
        if (this.isOpen) {
            this.close();
        }
        else {
            this.open();
        }
    }

    open() {
        if (this.isOpen) return;

        this.isOpen = true;
        
        const timeline = gsap.timeline()
            .fromTo(this.menu, {
                transform: 'translate3d(0, 100%, 0)',
            }, {
                duration: 1.2,
                transform: 'translate3d(0, 0%, 0)',
                ease: CustomEase.create('menu-transition', '0.62, 0.05, 0.01, 0.99'),
                onStart: () => {
                    this.showMenu();
                }
            });

        this.linkChars.forEach((chars) => {
            timeline.fromTo(chars, {
                y: '150%'
            }, {
                y: '0%',
                duration: 1.2,
                stagger: 0.02,
                ease: 'expo'
            }, '1-=0.4');
        });

        const styles = getComputedStyle(document.documentElement);
        const nextColor = styles.getPropertyValue('--color-decoration-invert');

        timeline.to(this.btn, {
            color: nextColor,
            duration: 1.2,
            ease: 'expo',
            clearProps: 'color',
            onStart: () => {
                this.btn.innerText = 'Close';
            },
            onComplete: () => {
                this.btn.classList.add('open');
            }
        }, '1-=0.2');

        timeline.fromTo(this.eye, {
            opacity: 0
        }, {
            opacity: 1,
            duration: 1.2,
            ease: 'expo'
        }, '1-=0');
    }

    close() {
        if (!this.isOpen) return;

        this.isOpen = false;

        const timeline = gsap.timeline()
            .fromTo(this.eye, {
                opacity: 1
            }, {
                opacity: 0,
                duration: 0.6,
                ease: 'expo'
            });

        const styles = getComputedStyle(document.documentElement);
        const nextColor = styles.getPropertyValue('--color-decoration');

        timeline.to(this.btn, {
            color: nextColor,
            duration: 0.6,
            ease: 'expo',
            clearProps: 'color',
            onStart: () => {
                this.btn.innerText = 'Menu';
            },
            onComplete: () => {
                this.btn.classList.remove('open');
            }
        }, '<-=0.2');

        timeline.fromTo(this.menu, {
                transform: 'translate3d(0, 0, 0)'
            }, {
                duration: 1.2,
                transform: 'translate3d(0, 100%, 0)',
                ease: CustomEase.create('menu-transition', '0.62, 0.05, 0.01, 0.99'),
                onComplete: () => {
                    this.hideMenu();
                }
            }, '1-=0.8');
    }
    
    setClose() {
        if (!this.isTablet) {
            return;
        }

        this.isOpen = false;
        
        this.hideMenu();

        const styles = getComputedStyle(document.documentElement);
        const nextColor = styles.getPropertyValue('--color-decoration');

        gsap.set(this.btn, {
            innerText: 'Menu',
            color: nextColor
        });
    }

    private showMenu() {
        gsap.set(this.menu, {
            opacity: 1,
            pointerEvents: 'all'
        });
    }

    private hideMenu() {
        gsap.set(this.menu, {
            opacity: 0,
            pointerEvents: 'none'
        });
    }

    private splitLinks() {
        this.linkChars = this.links.map((el) => new SplitType(el, { types: 'chars' }).chars);
    }

    private wrapLinks() {
        this.links = this.links.map((el) => {
            const inner = el.children.item(0);
            const wrapper = document.createElement('div');
            wrapper.style.overflow = 'hidden';
            el.appendChild(wrapper);
            wrapper.appendChild(inner);
            return inner as HTMLElement;
        });
    }

    private get isMobile() {
        return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    private get isTablet() {
        return window.innerWidth < 960;
    }
}
