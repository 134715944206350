export class ElementAnimation {
    readonly id: string;
    readonly element: HTMLElement;
    readonly duration: number;
    readonly delay: number;

    constructor(element: HTMLElement, index: number, name: string) {
        this.id = `${name}${index}`;
        this.element = element;
        this.element.id = this.id;
        this.duration = parseFloat(element.getAttribute(`data-${name}-duration`) ?? '1.2');
        this.delay = parseFloat(element.getAttribute(`data-${name}-delay`) ?? '0');
    }
}
