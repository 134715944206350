export class Magnetic {
    //dom elements
    private dom: {
        //element to listen on
        container: HTMLElement,
        //elements to transform
        elements: HTMLElement[]
    };
    //element position on page
    private position = {
        x: 0,
        y: 0
    };
    //distance to mouse
    private triggerDistance = 0;
    //multiplier to calc distance (multiplier * width of element)
    private distanceMultiplier: number;
    //mouse position on page
    private mouse = {
        x: 0,
        y: 0
    };
    //element transformation
    private transformations: {
        x: number,
        y: number,
        tx: number,
        ty: number,
        modifier: number
    }[] = [];
    //wether the effect is active
    private isMagnetic = false;
    //handles mouse move
    private mouseMoveHandler: (event: MouseEvent) => void;
    //animation frame
    private frame?: number;
    //animation ease
    private ease = 0.1;

    //create new magnetic effect
    constructor(container: Element, ...elements: Element[]) {
        this.dom = {
            container: container as HTMLElement,
            elements: elements as HTMLElement[]
        };
        this.distanceMultiplier = parseFloat(this.dom.container.getAttribute('data-magnetic-distance') ?? '1.5');
    }

    //setup
    init(start = true) {
        this.initTransformations();
        this.initPosition();
        this.initListener();
        start && this.startRender();
    }

    //teardown
    destroy() {
        this.removeListener();
    }

    //update trigger distance multiplier
    multiplyTriggerDistance(multiplier: number) {
        this.distanceMultiplier = multiplier;
    }

    //start effect rendering
    startRender() {
        if (!this.frame) {
            this.frame = requestAnimationFrame(() => this.render());
        }
    }

    //stop effect rendering
    stopRender() {
        if (this.frame) {
            cancelAnimationFrame(this.frame);
            this.frame = undefined;
        }
    }

    //reset effect
    reset() {
        this.stopRender();
        this.resetPositions();
        this.resetTransformations();
        this.transformElements();
    }

    //init transformation values
    private initTransformations() {
        this.dom.elements.forEach((element, index) => {
            const modifier = parseFloat(element.getAttribute('data-magnetic-mod') ?? '0.3');
            this.transformations[index] = {
                x: 0,
                y: 0,
                tx: 0,
                ty: 0,
                modifier
            };
        });
    }

    //init elements position and trigger distance
    private initPosition() {
        const parent = this.dom.container.offsetParent;
        const translate = this.calcTranslate(parent as HTMLElement);
        const rect = this.dom.container.getBoundingClientRect();
        this.position = {
            x: rect.left + (rect.width / 2) - translate.x,
            y: rect.top + (rect.height / 2) - translate.y
        };
        this.triggerDistance = rect.width * this.distanceMultiplier;
    }

    //init mouse listener
    private initListener() {
        this.mouseMoveHandler = this.onMouseMove.bind(this);
        window.addEventListener('mousemove', this.mouseMoveHandler, { passive: true });
    }

    //remove mouse listener
    private removeListener() {
        window.removeEventListener('mousemove', this.mouseMoveHandler);
    }

    //handle mouse move
    private onMouseMove(event: MouseEvent) {
        this.mouse.x = event.pageX;
        this.mouse.y = event.pageY;
    }

    //render effect
    private render() {
        this.frame = undefined;

        const distance = this.calcDistance();

        if (distance < this.triggerDistance) {
            if (!this.isMagnetic) {
                this.isMagnetic = true;
                this.enter();
            }
            this.updateTransformations();
        }
        else {
            this.isMagnetic = false;
            this.leave();
            this.resetTransformations();
        }

        this.interpolateTransformations();
        this.transformElements();

        this.frame = requestAnimationFrame(() => this.render());
    }

    //interpolate transformation values
    private interpolateTransformations() {
        this.dom.elements.forEach((element, index) => {
            this.transformations[index].x = this.interpolate(
                this.transformations[index].x,
                this.transformations[index].tx,
                this.ease
            );
            this.transformations[index].y = this.interpolate(
                this.transformations[index].y,
                this.transformations[index].ty,
                this.ease
            );
        });
    }

    //calc transformation values
    private updateTransformations() {
        this.dom.elements.forEach((element, index) => {
            this.transformations[index].tx = (this.mouse.x - this.position.x) * this.transformations[index].modifier;
            this.transformations[index].ty = (this.mouse.y - this.position.y) * this.transformations[index].modifier;
        });
    }

    //reset transformation values
    private resetTransformations() {
        this.dom.elements.forEach((element, index) => {
            this.transformations[index].tx = 0;
            this.transformations[index].ty = 0;
        });
    }

    //reset position values
    private resetPositions() {
        this.dom.elements.forEach((element, index) => {
            this.transformations[index].x = 0;
            this.transformations[index].y = 0;
        });
    }

    //transform element position
    private transformElements() {
        this.dom.elements.forEach((element, index) => {
            element.style.transform = `matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,${this.transformations[index].x},${this.transformations[index].y},0,1)`;
        });
    }

    //enter element bounds
    private enter() {
        this.dom.container.classList.add('magnetic');
    }

    //leave element bounds
    private leave() {
        this.dom.container.classList.remove('magnetic');
    }

    //interpolate between two values
    private interpolate(start: number, end: number, amount: number) {
        return (1 - amount) * start + amount * end;
    }

    //calculate mouse distance
    private calcDistance(): number {
        return Math.hypot(
            (this.mouse.x - this.position.x),
            (this.mouse.y - this.position.y)
        );
    }

    //calculate elements translation values
    private calcTranslate(element: HTMLElement) {
        const translate = {
            x: 0,
            y: 0
        };

        if (element) {
            const computedStyle = getComputedStyle(element);
            let transform = computedStyle.transform.match(/^matrix3d\((.+)\)$/);

            if (transform) {
                translate.x = transform ? parseFloat(transform[1].split(', ')[12]) : 0;
                translate.y = transform ? parseFloat(transform[1].split(', ')[13]) : 0;
            }
            else {
                transform = computedStyle.transform.match(/^matrix\((.+)\)$/);
                translate.x = transform ? parseFloat(transform[1].split(', ')[4]) : 0;
                translate.y = transform ? parseFloat(transform[1].split(', ')[5]) : 0;
            }
        }

        return translate;
        }
}
