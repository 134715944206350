import gsap from 'gsap';
import SplitType from 'split-type';
import {ElementAnimation} from './element.animation';
import { AnimationTarget } from './intersection-animator';

export class TextReveal extends ElementAnimation implements AnimationTarget {
    readonly lines: HTMLElement[];
    readonly stagger: number;
    inView = false;
    animateIn = true;
    animateOut = true;

    constructor(element: HTMLElement, index: number, options?: { animateIn?: boolean, animateOut?: boolean }) {
        super(element, index, 'reveal');
        this.lines = new SplitType(element, { types: 'lines' }).lines;
        this.stagger = parseFloat(this.element.getAttribute('data-reveal-stagger') ?? '0.04');

        if (options) {
            this.animateIn = options.animateIn ?? this.animateIn;
            this.animateOut = options.animateOut ?? this.animateOut;
        }

        this.wrapLines();
    }

    in() {
        if (this.inView || !this.animateIn) return;

        this.inView = true;

        gsap.fromTo(this.lines, {
            y: '150%'
        }, {
            y: '0%',
            duration: this.duration,
            delay: this.delay,
            stagger: this.stagger,
            ease: 'expo'
        });
    }

    setIn() {
        this.inView = true;
        gsap.set(this.lines, {
            y: '0%'
        });
    }

    out() {
        if (!this.inView || !this.animateOut) return;

        this.inView = false;

        gsap.fromTo(this.lines, {
            y: '0%'
        }, {
            y: '150%',
            duration: this.duration,
            delay: this.delay,
            stagger: this.stagger,
            ease: 'expo'
        });
    }

    setOut() {
        this.inView = false;
        gsap.set(this.lines,  {
            y: '150%'
        });
    }

    private wrapLines() {
        this.lines.forEach((line) => {
            const wrapper = document.createElement('div');
            wrapper.style.overflow = 'hidden';
            this.element.appendChild(wrapper);
            wrapper.appendChild(line);
        });
    }
}
