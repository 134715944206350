import { AnimationState } from './types';

export abstract class Animatable {
    state: AnimationState = 'stopped';

    abstract play(): void
    abstract pause(): void
    abstract onBegin(listener: () => void): void
    abstract onEnd(listener: () => void): void

    toggle() {
        if (this.state === 'stopped') {
            this.play();
            this.state = 'running';
        }
        else {
            this.pause();
            this.state = 'stopped';
        }
    }
}
