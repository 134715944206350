import { AnimationTimeline, isAnimationOptions, AnimationOptions } from './types';
import { Animatable } from './animatable';
import { Animation } from './animation';

export class Sequence extends Animatable {
    readonly elements: Animatable[] = [];
    readonly timeline: AnimationTimeline;

    constructor(
        timeline: AnimationTimeline, 
        ...nested: (Animatable | AnimationOptions)[]
    ) {
        super();
        this.timeline = timeline;
        nested.forEach((options) => {
            isAnimationOptions(options) ?
                this.elements.push(new Animation(options)) :
                this.elements.push(options)
        });
    }

    play() {
        if (this.timeline === 'sequential') {
            this.elements.reduce((prev, el) => {
                prev && prev.onEnd(() => el.play());

                return el;
            }, null);

            this.elements[0].play();

            return;
        }

        this.elements.forEach((el) => el.play());
    }

    pause() {
        this.elements.forEach((element) => element.pause());
    }

    onBegin(listener: () => void)  {
        this.elements[0].onBegin(listener);
    }

    onEnd(listener: () => void) {
        let left = this.elements.length;

        this.elements.forEach((el) => {
            el.onEnd(() => {
                left--;

                if (left === 0) listener();
            });
        });
    }
}

