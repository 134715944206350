import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

const pageTransition = document.querySelector('.page-transition') as HTMLElement;

export const pageTransitionIn = () => {
    gsap.set(pageTransition, { opacity: 1 });
    return gsap.fromTo(pageTransition, {
        transform: 'translate3d(0, 100%, 0)',
    }, {
        duration: 1.2,
        transform: 'translate3d(0, 0%, 0)',
        ease: CustomEase.create('page-transition', '0.62, 0.05, 0.01, 0.99'),
        onStart: () => {
            pageTransition.style.opacity = '1';
        },
        onComplete: () => {
            window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
        }
    }) as any;
};

export const pageTransitionOut = () => {
    return gsap.fromTo(pageTransition, {
        transform: 'translate3d(0, 0%, 0)',
    }, {
        duration: 1.2,
        transform: 'translate3d(0, -100%, 0)',
        ease: CustomEase.create('page-transition', '0.62, 0.05, 0.01, 0.99'),
        onComplete: () => {
            pageTransition.style.opacity = '0';
        }
    }) as any;
};

export const isMobile = () => {
        return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
