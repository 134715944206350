import gsap, {Elastic} from 'gsap';

export class ElasticLine {
    //dom elements
    private dom: {
        element: SVGElement,
        path: SVGPathElement
    };
    //mouse position
    private mouse = {
        x: 0,
        y: 0
    };
    //target curve position
    private target = {
        x: 0,
        y: 0
    };
    //element position on page
    private position = {
        x: 0,
        y: 0
    };
    //distance to mouse
    private distance = {
        x: 0,
        y: 0
    };
    //distance to trigger in px
    private triggerDistance = 150;
    //animation frame
    private frame?: number;
    //wether the effect is active
    private isMagnetic = false;
    //handle mouse move
    private mouseMoveHandler: (event: MouseEvent) => void;
    //animation ease
    private ease = 0.1;

    //create new elastic line
    constructor(element: Element) {
        this.dom = {
            element: element as SVGElement,
            path: element.querySelector('path') as SVGPathElement
        };
    }

    //setup
    init() {
        this.initPosition();
        this.initListener();
    }

    //teardown
    destroy() {
        this.removeListener();
    }

    //init element position
    private initPosition() {
        const rect = this.dom.element.getBoundingClientRect();
        this.position = {
            x: rect.left + (rect.width / 2),
            y: rect.top + (rect.height / 2)
        };
    }

    //setup mouse listener
    private initListener() {
        this.mouseMoveHandler = this.onMouseMove.bind(this);
        window.addEventListener('mousemove', this.mouseMoveHandler, { passive: true });
    }
    
    //remove mouse listener
    private removeListener() {
        window.removeEventListener('mousemove', this.mouseMoveHandler);
    }

    //listen to mouse event
    private onMouseMove(event: MouseEvent) {
        this.mouse.x = event.pageX;
        this.mouse.y = event.pageY;

        this.distance.x = this.mouse.x - this.position.x;
        this.distance.y = this.mouse.y - this.position.y;

        if (this.isMagnetic) {
            if (Math.abs(this.distance.y) > this.triggerDistance) {
                this.isMagnetic = false;
                this.leave();
            }
        }
        else {
            if (Math.abs(this.distance.y) < this.triggerDistance / 2) {
                this.isMagnetic = true;
                this.enter();
            }
        }
    }

    //enter element bounds
    private enter() {
        this.startRender();
    }

    //leave element bounds
    private leave() {
        this.stopRender();
        gsap.killTweensOf(this.dom.path);
        gsap.to(this.dom.path, {
            ease: Elastic.easeOut.config(1, 0.3),
            duration: 1.2,
            attr: {
                d: `M0,500 Q1000,500 2000,500`
            }
        });
    }

    //start effect render
    private startRender() {
        if (!this.frame) {
            this.frame = requestAnimationFrame(() => this.render());
        }
    }

    //loop effect render
    private render() {
        this.frame = undefined;

        this.target.y = gsap.utils.interpolate(this.target.y, this.distance.y, this.ease);
        this.target.x = gsap.utils.interpolate(this.target.x, this.distance.x, this.ease);

        const mappedX = gsap.utils.mapRange(-200, 200, 800, 1200, this.target.x);
        const mappedY = gsap.utils.mapRange(-200, 200, 0, 1000, this.target.y);

        gsap.set(this.dom.path, {
            attr: {
                d: `M0,500 Q${mappedX},${mappedY} 2000,500`
            }
        });

        this.frame = requestAnimationFrame(() => this.render());
    }

    //stop effect render
    private stopRender() {
        if (this.frame) {
            cancelAnimationFrame(this.frame);
            this.frame = undefined;
        }
    }
}
