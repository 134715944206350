import gsap from 'gsap';
import SplitType from 'split-type';

export class TextButton {
    private element: HTMLElement;
    private chars: HTMLElement[];
    private charsHidden: HTMLElement[];
    private isActive = false;

    constructor(element: Element) {
        this.element = element as HTMLElement;
        const inner = this.element.querySelector('.text-button-inner-text') as HTMLElement;
        const innerHidden = this.element.querySelector('.text-button-inner-text-hidden') as HTMLElement;
        this.chars = new SplitType(inner, { types: 'chars' }).chars;
        this.charsHidden = new SplitType(innerHidden, { types: 'chars' }).chars;
        gsap.set(this.charsHidden, { y: '100%' });
        this.setupListeners();
    }

    destroy() {
        this.element.removeEventListener('mouseenter', this.onMouseEnter.bind(this));
        this.element.removeEventListener('mouseleave', this.onMouseLeave.bind(this));
    }

    private setupListeners() {
        this.element.addEventListener('mouseenter', this.onMouseEnter.bind(this), { passive: true });
        this.element.addEventListener('mouseleave', this.onMouseLeave.bind(this), { passive: true });
    }

    private onMouseEnter() {
        if (this.isActive) return;

        this.isActive = true;

        gsap.to(this.chars, {
            y: '-100%',
            duration: 1,
            ease: 'expo',
            stagger: 0.02
        });

        gsap.to(this.charsHidden, {
            y: '0%',
            duration: 1,
            ease: 'expo',
            stagger: 0.02
        });
    }

    private onMouseLeave() {
        if (!this.isActive) return;

        this.isActive = false;

        gsap.to(this.chars, {
            y: '0%',
            duration: 1,
            ease: 'expo',
            stagger: 0.02
        });

        gsap.to(this.charsHidden, {
            y: '100%',
            duration: 1,
            ease: 'expo',
            stagger: 0.02
        });
    }
}
