export class Carousel {
    private _index = 0;
    private _animating = false;
    private _frame?: number;
    private _element: HTMLElement;
    private _x = 0;
    private _targetX = 0;
    private _elements: HTMLElement[];
    private _cb?: () => void;

    constructor(element: Element, children: Element[]) {
        this._element = element as HTMLElement;
        this._elements = children as HTMLElement[];
    }

    first(cb?: () => void) {
        this._cb = cb;
        this.index = 0;
    }

    last(cb?: () => void) {
        this._cb = cb;
        this.index = this.length - 1;
    }

    next(cb?: () => void) {
        this._cb = cb;
        this.index = this._index + 1;
    }

    prev(cb?: () => void) {
        this._cb = cb;
        this.index = this._index - 1;
    }

    to(index: number, cb?: () => void) {
        this._cb = cb;
        this.index = index;
    }

    private start() {
        this._animating = true;
        this.continue();
    }

    private continue() {
        if (!this._animating) return;

        this._frame = requestAnimationFrame(() => this.continue());
        
        this._x += (this._targetX - this._x) * 0.2;

        if (Math.abs(this._targetX - this._x) < 1) {
            this.stop();
            return;
        }

        this._elements.forEach((el) => this.transformEl(el, -this._x));
    }

    private stop() {
        this._animating = false;
        this._frame && cancelAnimationFrame(this._frame);

        this._x = this._targetX;
        this._elements.forEach((el) => this.transformEl(el, -this._x));

        this._cb && this._cb();
        this._cb = undefined;
    }

    private get length() {
        return this._elements.length;
    }

    private set index(index: number) {
        if (index < 0) this._index = 0;
        else if (index > this.length - 1) this._index = this.length - 1;
        else this._index = index;

        this._targetX = this._elements[this._index].offsetLeft;

        if (!this._animating) {
            this.start();
        }
    }

    private transformEl(el: HTMLElement, x: number) {
        el.style.transform = `translate3d(${x}px, 0, 0)`;
    }
}
