import * as Three from 'three';
import {createUniforms} from './helper';
import { Sketch } from './sketch';

export class Shader extends Sketch {
    private mesh: Three.Mesh;
    private vertex: string;
    private fragment: string;
    private geometry?: Three.BufferGeometry;
    private uniforms = createUniforms();

    constructor(
        container: Element, 
        vertex: string,
        fragment: string,
        geometry?: Three.BufferGeometry
    ) {
        super(container);
        this.vertex = vertex;
        this.fragment = fragment;
        this.geometry = geometry;
    }

    setupObjects() {
        this.uniforms.iResolution.value.set(this.viewport.width, this.viewport.height, 0);
        this.setupShader();
    }

    renderObjects() {
        this.uniforms.iTime.value = this.time;

        this.mesh.rotation.y = this.linearInterpolate(this.mesh.rotation.y, this.mouse.x, 0.055) - 0.15;
        this.mesh.rotation.x = this.linearInterpolate(this.mesh.rotation.x, this.mouse.y, 0.055);
    }

    resizeObjects() {}

    private setupShader() {
        const sizes = {
            768: 1.3,
            1080: 1.4,
        };
        let size = 1.2;
        Object.entries(sizes).forEach(([breakpoint, breakpointSize]) => {
            size = window.innerHeight >= parseInt(breakpoint) ? breakpointSize : size;
        });
        const geometry = this.geometry ?? new Three.SphereGeometry(size, 32, 32);
        const material = new Three.ShaderMaterial({
            vertexShader: this.vertex,
            fragmentShader: this.fragment,
            uniforms: this.uniforms
        });
        this.mesh = new Three.Mesh(geometry, material);
        this.scene.add(this.mesh);
    }
}
