import * as Three from 'three';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';

export abstract class Sketch extends EventTarget {
    protected container: Element;
    protected viewport = {
        width: 0,
        height: 0
    };
    protected mouse = {
        x: 0,
        y: 0
    };

    protected scene: Three.Scene;
    protected renderer: Three.WebGLRenderer;
    protected composer?: EffectComposer;
    protected camera: Three.PerspectiveCamera;
    protected clock: Three.Clock;

    protected time: number;
    protected isPlaying = false;
    private animationFrame?: number;
    private resize = false;

    static events = {
        READY: 'ready'
    };

    constructor(container: Element, resize = false) {
        super();
        this.container = container;
        this.resize = resize;
        this.setup();
    }

    play() {
        this.isPlaying = true;
        this.render();
    }

    pause() {
        if (this.animationFrame) {
            cancelAnimationFrame(this.animationFrame);
        }
        this.isPlaying = false;
    }

    init() {
        this.setupObjects();
        this.clock.start();
        this.signalReady();
    }

    destroy() {
        window.removeEventListener('resize', this.onResize.bind(this));
        window.removeEventListener('mousemove', this.onMouseMove.bind(this));
        this.renderer.dispose();
        this.pause();
    }

    protected abstract setupObjects(): void

    protected abstract renderObjects(): void

    protected abstract resizeObjects(): void

    protected createComposer() {
        const composer = new EffectComposer(this.renderer);
        composer.setSize(this.viewport.width, this.viewport.height);
        composer.addPass(new RenderPass(this.scene, this.camera));
        return composer;
    }

    protected render() {
        if (!this.isPlaying) {
            return;
        }

        this.animationFrame = requestAnimationFrame(() => this.render());

        this.time = this.clock.getElapsedTime();

        this.renderObjects();

        this.composer ? this.composer.render() : this.renderer.render(this.scene, this.camera);
    }

    private signalReady() {
        this.dispatchEvent(new CustomEvent(Sketch.events.READY));
    }

    private setup() {
        this.resizeViewport();
        this.setupThree();
        this.resize && this.setupResize();
        this.setupMouseListener();
    }

    private setupThree() {
        this.scene = new Three.Scene();
        this.clock = new Three.Clock();
        this.setupRenderer();
        this.setupCamera();
    }
    
    private setupRenderer() {
        this.renderer = new Three.WebGLRenderer({
            alpha: true,
            antialias: true,
            powerPreference: 'high-performance'
        });
        this.renderer.setSize(this.viewport.width, this.viewport.height);
        this.renderer.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1);
        this.renderer.setClearColor(0x000000, 0.0);
        this.container.prepend(this.renderer.domElement);
    }

    private resizeRenderer() {
        this.renderer.setSize(this.viewport.width, this.viewport.height);
        if (this.composer) {
            this.composer.setSize(this.viewport.width, this.viewport.height);
        }
    }
    
    private setupCamera() {
        this.camera = new Three.PerspectiveCamera(75, this.viewport.width / this.viewport.height, 0.1, 10);
        this.camera.position.set(0, 0, 2.5);
        this.camera.lookAt(0, 0, 0);
        this.scene.add(this.camera);
    }

    private resizeCamera() {
        this.camera.aspect = this.viewport.width / this.viewport.height;
        this.camera.updateProjectionMatrix();
    }

    private resizeViewport() {
        this.viewport.width = (this.container as HTMLElement).offsetWidth;
        this.viewport.height = (this.container as HTMLElement).offsetHeight;
    }

    private setupResize() {
        window.addEventListener('resize', this.onResize.bind(this), { passive: true });
    }

    private setupMouseListener() {
        window.addEventListener('mousemove', this.onMouseMove.bind(this), { passive: true });
    }

    private onResize() {
        this.resizeViewport();
        this.resizeRenderer();
        this.resizeCamera();
        this.signalReady();
    }

    private onMouseMove(event: MouseEvent) {
        this.mouse.x = this.linearInterpolate(this.mouse.x, event.clientX / this.viewport.width - 0.5, 1);
        this.mouse.y = this.linearInterpolate(this.mouse.y, event.clientY / this.viewport.height - 0.5, 1);
    }

    protected linearInterpolate(start: number, end: number, amount: number) {
        return (1 - amount) * start + amount * end;
    }
}
