export type CursorTarget = {
    element: HTMLElement,
    cursor?: HTMLElement,
    trail?: HTMLElement,
    cursorCls?: string,
    trailCls?: string
};

export class Cursor {
    private cursor?: HTMLElement;
    private trail?: HTMLElement;
    private activeCursor?: HTMLElement;
    private activeTrail?: HTMLElement;
    private targets: CursorTarget[] = [];
    private activeTarget?: CursorTarget;
    private trailposition = {
        x: 0,
        y: 0
    };
    private mouseposition = {
        x: 0,
        y: 0
    };
    private animationFrame?: number;
    isOnTarget = false;
    interpolationEase = 0.1;

    constructor(
        cursor?: HTMLElement,
        trail?: HTMLElement,
        interpolationEase?: number
    ) {
        this.cursor = cursor;
        this.activeCursor = cursor;

        this.trail = trail;
        this.activeTrail = trail;

        this.syncMouseposition = this.syncMouseposition.bind(this);
        
        if (interpolationEase) {
            this.interpolationEase = interpolationEase;
        }

        this.setup();
    }

    private setup() {
        this.addMouseListener();
        this.animationFrame = requestAnimationFrame(() => this.animate());

        this.init();
    }

    init() {
        this.targets = this.initTargets();
        this.addTargetListeners();
    }
    
    destroy() {
        if (this.animationFrame) {
            cancelAnimationFrame(this.animationFrame);
        }
        this.removeMouseListener();
        this.removeTargetListeners();
    }

    resetActive() {
        if (!this.activeTarget) {
            return;
        }
        this.handleTargetLeft(this.activeTarget);
    }

    private initTargets(): CursorTarget[] {
        const targetEl = Array.from(document.querySelectorAll('[data-target]')) as HTMLElement[];

        return targetEl.map((el: HTMLElement) => {
            const target: CursorTarget = { element: el };

            const cursorSelector = el.getAttribute('data-target-cursor');
            if (cursorSelector) {
                target.cursor = document.querySelector(cursorSelector) as HTMLElement;
            }

            const trailSelector = el.getAttribute('data-target-trail');
            if (trailSelector) {
                target.trail = document.querySelector(trailSelector) as HTMLElement;
            }

            const cursorCls = el.getAttribute('data-target-cursor-class');
            if (cursorCls) {
                target.cursorCls = cursorCls;
            }

            const trailCls = el.getAttribute('data-target-trail-class');
            if (trailCls) {
                target.trailCls = trailCls;
            }

            return target;
        });
    }

    private addMouseListener() {
        window.addEventListener('mousemove', this.syncMouseposition, { passive: true });
    }

    private addTargetListeners() {
        this.targets.forEach((target: CursorTarget) => {
            target.element.addEventListener(
                'mouseenter', 
                this.handleTargetEntered.bind(this, target), 
                { passive: true }
            );

            target.element.addEventListener(
                'mouseleave', 
                this.handleTargetLeft.bind(this, target), 
                { passive: true }
            );
        });
    }

    private removeMouseListener() {
        window.removeEventListener('mousemove', this.syncMouseposition);
    }

    private removeTargetListeners() {
        this.targets.forEach((target: CursorTarget) => {
            target.element.removeEventListener('mouseenter', this.handleTargetEntered.bind(this, target));
            target.element.removeEventListener('mouseleave', this.handleTargetLeft.bind(this, target));
        });
    }

    private animate() {
        if (!this.activeCursor && !this.activeTrail) {
            this.animationFrame = undefined;
            return;
        }

        if (this.activeCursor) {
            this.transformElement(this.activeCursor, this.mouseposition.x, this.mouseposition.y);
        }

        if (this.activeTrail) {
            this.trailposition.x += (this.mouseposition.x - this.trailposition.x) * this.interpolationEase;
            this.trailposition.y += (this.mouseposition.y - this.trailposition.y) * this.interpolationEase;

            this.transformElement(this.activeTrail, this.trailposition.x, this.trailposition.y);
        }

        this.animationFrame = requestAnimationFrame(() => this.animate());
    }

    private handleTargetEntered(target: CursorTarget) {
        if (!this.isOnTarget) {
            this.isOnTarget = true;
            this.activeTarget = target;

            if (target.cursor) {
                this.setActiveCursor(target.cursor);
            }

            if (target.trail) {
                this.setActiveTrail(target.trail);
            }

            if (target.cursorCls) {
                this.setCursorCls(target.cursorCls);
            }

            if (target.trailCls) {
                this.setTrailCls(target.trailCls);
            }

            if (!this.animationFrame) {
                requestAnimationFrame(() => this.animate());
            }
        }
    }

    private handleTargetLeft(target: CursorTarget) {
        if (this.isOnTarget) {
            this.isOnTarget = false;
            this.activeTarget = undefined;
            this.resetCursor();
            this.resetTrail();
            this.resetCursorCls(target);
            this.resetTrailCls(target);
        }
    }

    private setActiveCursor(cursor: HTMLElement) {
        this.activeCursor?.classList.add('hidden');
        this.activeCursor = cursor;
        this.activeCursor.classList.remove('hidden');
        this.setToMousePosition(this.activeCursor);
    }

    private setActiveTrail(trail: HTMLElement) {
        this.activeTrail?.classList.add('hidden');
        this.activeTrail = trail;
        this.resetTrailposition();
        this.setToTrailPosition(this.activeTrail);
        this.activeTrail.classList.remove('hidden');
    }

    private resetCursor() {
        this.activeCursor?.classList.add('hidden');
        this.cursor?.classList.remove('hidden');
        this.activeCursor = this.cursor;
    }

    private resetTrail() {
        this.activeTrail?.classList.add('hidden');
        this.trail?.classList.remove('hidden');
        this.activeTrail = this.trail;
    }

    private setCursorCls(cls: string) {
        this.activeCursor?.classList.add(cls);
    }

    private setTrailCls(cls: string) {
        this.activeTrail?.classList.add(cls);
    }

    private resetCursorCls(target: CursorTarget) {
        if (!target.cursorCls) {
            return;
        }
        this.activeCursor?.classList.remove(target.cursorCls);
    }

    private resetTrailCls(target: CursorTarget) {
        if (!target.trailCls) {
            return;
        }
        this.activeTrail?.classList.remove(target.trailCls);
    }

    private syncMouseposition(event: MouseEvent) {
        this.mouseposition.x = event.clientX;
        this.mouseposition.y = event.clientY;
    }

    private setToMousePosition(element: HTMLElement) {
        this.transformElement(element, this.mouseposition.x, this.mouseposition.y);
    }

    private setToTrailPosition(element: HTMLElement) {
        this.transformElement(element, this.trailposition.x, this.trailposition.y);
    }

    private resetTrailposition() {
        this.trailposition.x = this.mouseposition.x;
        this.trailposition.y = this.mouseposition.y;
    }

    private transformElement(element: HTMLElement, x: number, y: number) {
        element.style.transform = `matrix3d(1,0,0.00,0,0.00,1,0.00,0,0,0,1,0,${x},${y},0,1)`;
    }

    private isMobile() {
        return /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    private isTablet() {
        return window.innerWidth < 768;
    }
}

