import gsap from 'gsap';
import {ElementAnimation} from './element.animation';
import { AnimationTarget } from './intersection-animator';

export class LineDraw extends ElementAnimation implements AnimationTarget {
    private path?: SVGPathElement;
    inView = false;

    constructor(element: HTMLElement, index: number) {
        super(element, index, 'line');
        this.path = this.element.querySelector('path');
    }

    in() {
        if (this.inView) return;

        this.inView = true;

        gsap.fromTo(this.element, {
            scaleX: '0.01%'
        }, {
            scaleX: '100%',
            duration: this.duration,
            delay: this.delay,
            ease: 'expo.out',
        });
    }

    out() {
    }

    setIn() {
        this.inView = true;
        gsap.set(this.element, { scaleX: '100%' });
    }

    setOut() {
        this.inView = false;
        gsap.set(this.element, { scaleX: '0.01%' });
    }
}
