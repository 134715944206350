import * as Animation from '../animation';

const router = 'Router';
const sensors = ['Humidity', 'AirQuality', 'Temperature'];
const actors = ['Heating', 'Ventilation', 'Cooling'];
const targets = ['Database', 'Control', 'Desktop', 'Mobile'];

export type IotAnimationItem = {
        btn: HTMLElement,
        text: HTMLElement[],
        animation: Animation.Animatable,
        playing: boolean
}

export class IotAnimation {
    private carousel: Animation.Carousel;
    private carouselCtrl: Record<string, Element> = {};
    private animationSteps: Record<string, Animation.Animatable> = {};
    private animations: Record<string, IotAnimationItem> = {};

    init() {
        this.initCarousel();
        this.initAnimationSteps();
        this.initAnimations();
        this.initListeners();
    }

    private toggleButton(animation: IotAnimationItem) {
        animation.btn.innerText = animation.playing ? 'Stop' : 'Play';
    }

    private hideText(animation: IotAnimationItem, index: number) {
        animation.text[index].classList.remove('active');
    };

    private showText(animation: IotAnimationItem, index: number) {
        animation.text[index].classList.add('active');
    };

    private stopAll() {
        Object.values(this.animations).forEach((animation) => {
            if (animation.playing) {
                this.stopAnimation(animation);
            }
        });
    }

    private stopAnimation(animation: IotAnimationItem) {
        animation.animation.pause();
        animation.playing = false;
        animation.btn.innerText = 'Play';
        this.hideAllText(animation);
    }

    private hideAllText(animation: IotAnimationItem) {
        Object.values(animation.text).forEach((text) => text.classList.remove('active'));
    }

    private initListeners() {
        Object.values(this.animations).forEach((animation) => {
            animation.btn.addEventListener('click', () => {
                if (animation.playing) {
                    animation.playing = false;
                    animation.animation.pause();
                    this.hideAllText(animation);
                    animation.btn.innerText = 'Play';
                }
                else {
                    this.stopAll();
                    animation.playing = true;
                    animation.animation.play();
                    animation.btn.innerText = 'Stop';
                }
            });
        });
    }

    private initCarousel() {
        const ctrl = document.querySelector('.iot-ctrl');
        const items = Array.from(document.querySelectorAll('.iot-ctrl-item'));

        this.carousel = new Animation.Carousel(ctrl, items);

        this.carouselCtrl.left = document.querySelector('.iot-ctrl-carousel-left');
        this.carouselCtrl.right = document.querySelector('.iot-ctrl-carousel-right');

        this.carouselCtrl.left.addEventListener('click', () => this.carousel.prev());
        this.carouselCtrl.right.addEventListener('click', () => this.carousel.next());
    }

    private initAnimations() {
        this.animations.sensor = {
            btn: document.querySelector('#play-sensor'),
            text: Array.from(document.querySelectorAll('.iot-sensor-text')),
            playing: false,
            animation: this.initSensorAnimation()
        };
        this.animations.config = {
            btn: document.querySelector('#play-config'),
            text : Array.from(document.querySelectorAll('.iot-config-text')),
            playing: false,
            animation: this.initConfigAnimation()
        };

        this.animations.alarm = {
            btn: document.querySelector('#play-alarm'),
            text: Array.from(document.querySelectorAll('.iot-alarm-text')),
            playing: false,
            animation: this.initAlarmAnimation()
        };

        this.animations.action = {
            btn: document.querySelector('#play-action'),
            text: Array.from(document.querySelectorAll('.iot-action-text')),
            playing: false,
            animation: this.initActionAnimation()
        };
    }

    private initSensorAnimation() {
        return new Animation.Sequence(
            'sequential', 

            /*
             *Sensor publishes data
             */

            new Animation.Adapter((done) => {
                this.showText(this.animations.sensor, 0);
                done();
            }),

            this.animationSteps.fromSensorsToRouter, 

            /*
             *Message broker sends data to all subscribers
             */

            new Animation.Adapter((done) => {
                this.hideText(this.animations.sensor, 0);
                this.showText(this.animations.sensor, 1);
                done();
            }),

            new Animation.Sequence(
                'parallel',
                this.animationSteps.routerEmits,
                this.animationSteps.fromRouterToTargetsSensors,
            ),

            /*
             *Control unit reacts on incoming sensor data and publishes commands
             */

            new Animation.Adapter((done) => {
                this.hideText(this.animations.sensor, 1);
                this.showText(this.animations.sensor, 2);
                done();
            }),

            this.animationSteps.fromControlToRouterActors,

            /*
             *Message broker sends commands to all subscribers
             */

            new Animation.Adapter((done) => {
                this.hideText(this.animations.sensor, 2);
                this.showText(this.animations.sensor, 3);
                done();
            }),

            new Animation.Sequence(
                'parallel',
                this.animationSteps.fromRouterToActorsCmd,
                this.animationSteps.fromRouterToTargetsActors
            ),

            new Animation.Sequence(
                'parallel',
                this.animationSteps.animateCooling,
                this.animationSteps.animateHeating,
                this.animationSteps.animateVentilation
            ),

            /*
             *End
             */

            new Animation.Adapter(() => {
                this.hideText(this.animations.sensor, 3);
                this.animations.sensor.playing = false;
                this.toggleButton(this.animations.sensor);
            })
        );
    }

    private initConfigAnimation() {
        return new Animation.Sequence(
            'sequential',

            /*
             *Configuring the application
             */

            new Animation.Adapter((done) => {
                this.showText(this.animations.config, 0);
                done();
            }),

            this.animationSteps.fromDesktopToRouterConfig,

            /*
             *Message broker sends configuration to all subscribers
             */

            new Animation.Adapter((done) => {
                this.hideText(this.animations.config, 0);
                this.showText(this.animations.config, 1);
                done();
            }),

            new Animation.Sequence(
                'parallel',
                this.animationSteps.routerEmits,
                this.animationSteps.fromRouterToTargetsConfig
            ),

            /*
             *End
             */

            new Animation.Adapter(() => {
                this.hideText(this.animations.config, 1);
                this.animations.config.playing = false;
                this.toggleButton(this.animations.config);
            })
        );
    }

    private initAlarmAnimation() {
        return new Animation.Sequence(
            'sequential',

            /*
             *Control unit raises an alarm
             */

            new Animation.Adapter((done) => {
                this.showText(this.animations.alarm, 0);
                done();
            }),

            this.animationSteps.fromControlToRouterAlert,

            /*
             *Message broker sends alarm to all subscribers
             */

            new Animation.Adapter((done) => {
                this.hideText(this.animations.alarm, 0);
                this.showText(this.animations.alarm, 1);
                done();
            }),

            new Animation.Sequence(
                'parallel',
                this.animationSteps.routerEmits,
                this.animationSteps.fromRouterToTargetsAlert
            ),

            /*
             *Operator publishes a reaction
             */

            new Animation.Adapter((done) => {
                this.hideText(this.animations.alarm, 1);
                this.showText(this.animations.alarm, 2);
                done();
            }),

            this.animationSteps.fromMobileToRouterAction,

            /*
             *Message broker sends action to all subscribers
             */

            new Animation.Adapter((done) => {
                this.hideText(this.animations.alarm, 2);
                this.showText(this.animations.alarm, 3);
                done();
            }),

            new Animation.Sequence(
                'parallel',
                this.animationSteps.routerEmits,
                this.animationSteps.fromRouterToTargetsAction,
            ),

            /*
             *Control unit publishes command
             */

            new Animation.Adapter((done) => {
                this.hideText(this.animations.alarm, 3);
                this.showText(this.animations.alarm, 4);
                done();
            }),

            this.animationSteps.fromControlToRouterActors,

            /*
             *Message broker sends commands to all subscribers
             */

            new Animation.Adapter((done) => {
                this.hideText(this.animations.alarm, 4);
                this.showText(this.animations.alarm, 5);
                done();
            }),

            new Animation.Sequence(
                'parallel',
                this.animationSteps.routerEmits,
                this.animationSteps.fromRouterToActorsCmd,
            ),

            new Animation.Sequence(
                'parallel',
                this.animationSteps.animateCooling,
                this.animationSteps.animateHeating,
                this.animationSteps.animateVentilation
            ),

            /*
             *End
             */

            new Animation.Adapter(() => {
                this.hideText(this.animations.alarm, 5);
                this.animations.alarm.playing = false;
                this.toggleButton(this.animations.alarm);
            })
        );
    }

    private initActionAnimation() {
        return new Animation.Sequence(
            'sequential',

            /*
             *Operator publishes action
             */

            new Animation.Adapter((done) => {
                this.showText(this.animations.action, 0);
                done();
            }),

            this.animationSteps.fromDesktopToRouterAction,

            /*
             *Message broker sends action to all subscribers
             */

            new Animation.Adapter((done) => {
                this.hideText(this.animations.action, 0);
                this.showText(this.animations.action, 1);
                done();
            }),

            new Animation.Sequence(
                'parallel',
                this.animationSteps.routerEmits,
                this.animationSteps.fromRouterToTargetsAction,
            ),

            /*
             *Control unit publishes commands
             */

            new Animation.Adapter((done) => {
                this.hideText(this.animations.action, 1);
                this.showText(this.animations.action, 2);
                done();
            }),

            this.animationSteps.fromControlToRouterActors,

            /*
             *Message broker sends commands to all subscribers
             */

            new Animation.Adapter((done) => {
                this.hideText(this.animations.action, 2);
                this.showText(this.animations.action, 3);
                done();
            }),

            new Animation.Sequence(
                'parallel',
                this.animationSteps.routerEmits,
                this.animationSteps.fromRouterToTargetsActors,
                this.animationSteps.fromRouterToActorsCmd
            ),

            new Animation.Sequence(
                'parallel',
                this.animationSteps.animateCooling,
                this.animationSteps.animateHeating,
                this.animationSteps.animateVentilation
            ),

            /*
             *End
             */

            new Animation.Adapter(() => {
                this.hideText(this.animations.action, 3);
                this.animations.action.playing = false;
                this.toggleButton(this.animations.action);
            })
        );
    }

    private initAnimationSteps() {
        this.animationSteps = {
            sensorEmits: new Animation.Animation({ id: '#Emit_Sensor_Temperature' }),

            routerEmits: new Animation.Animation({ id: '#Emit_Router' }),

            animateHeating: new Animation.Animation({ id: '#Icon_Actor_Heating_Animate' }),

            animateVentilation: new Animation.Animation({ id: '#Icon_Actor_Ventilation_Animate' }),

            animateCooling: new Animation.Animation({ id: '#Icon_Actor_Cooling_Animate' }),

            animateAppChart: new Animation.Animation({ id: '#App_Chart_Animate' }),

            fromSensorsToRouter: Animation.Builder
                .timeline('parallel')
                .from(...sensors)
                .to(router)
                .delay('from', 400, 800)
                .create(),

            fromRouterToTargetsSensors: Animation.Builder
                .timeline('parallel')
                .from(router)
                .to(...targets)
                .object(...sensors)
                .delay('object', 400, 800)
                .create(),

            fromControlToRouterActors: Animation.Builder
                .from('Control')
                .to(router)
                .object(...actors)
                .delay('object', 400, 800)
                .create(),

            fromControlToRouterAlert: Animation.Builder
                .from('Control')
                .to(router)
                .object('Alert')
                .create(),

            fromRouterToTargetsAlert: Animation.Builder
                .from(router)
                .to('Desktop', 'Mobile', 'Database')
                .object('Alert')
                .create(),

            fromRouterToActorsCmd: Animation.Builder
                .from(router)
                .to('Heating', 'Ventilation', 'Cooling')
                .object('Cmd')
                .delay('to', 400, 800)
                .create(),

            fromRouterToTargetsActors: Animation.Builder
                .from(router)
                .to('Mobile', 'Desktop', 'Database')
                .object('Heating', 'Ventilation', 'Cooling')
                .delay('object', 400, 800)
                .create(),

            fromRouterToTargetsConfig: Animation.Builder
                .from(router)
                .to('Control', 'Database', 'Desktop', 'Mobile')
                .object('Config')
                .create(),

            fromDesktopToRouterAction: Animation.Builder
                .from('Desktop')
                .to(router)
                .object('Action')
                .delay('object', 400, 800)
                .create(),

            fromDesktopToRouterConfig: Animation.Builder
                .from('Desktop')
                .to(router)
                .object('Config')
                .create(),

            fromMobileToRouterAction: Animation.Builder
                .from('Mobile')
                .to(router)
                .object('Action')
                .create(),

            fromRouterToTargetsAction: Animation.Builder
                .from(router)
                .to(...targets)
                .object('Action')
                .create()
        };
    }
}
