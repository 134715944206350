import { Animatable } from './animatable';
import { AnimationOptions } from './types';

export class Animation extends Animatable {
    private timeout?: number;
    private beginListener?: () => any;
    private endListener?: () => any;
    readonly element: SVGAnimateElement;
    readonly delay: number;

    constructor(options: AnimationOptions) {
        super();
        this.element = document.querySelector(options.id) as SVGAnimateElement;
        this.delay = options.delay || 0;
    }

    play() {
        if (this.delay > 0) {
            this.timeout = setTimeout(() => this.element.beginElement(), this.delay) as any;
        }
        else {
            this.element.beginElement();
        }
    }

    pause() {
        this.beginListener && this.element.removeEventListener('beginEvent', this.beginListener);
        this.endListener && this.element.removeEventListener('endEvent', this.endListener);
        this.timeout && clearTimeout(this.timeout);
    }

    onBegin(listener: () => void)  {
        const once = () => {
            listener();
            this.element.removeEventListener('endEvent', once);
            this.beginListener = undefined;
        };
        this.beginListener = once;
        this.element.addEventListener('beginEvent', once);
    }

    onEnd(listener: () => void) {
        const once = () => {
            listener();
            this.element.removeEventListener('endEvent', once);
            this.endListener = undefined;
        };
        this.endListener = once;
        this.element.addEventListener('endEvent', once);
    }
}

