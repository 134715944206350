import { IContactFormData } from './contact.interface';

class Listener {
    private fn: EventListenerOrEventListenerObject;

    constructor(
        private target: HTMLElement,
        private type: string,
        cb: EventListenerOrEventListenerObject,
        private options?: boolean | AddEventListenerOptions,
    ) {
        this.fn = cb;
        this.target?.addEventListener(this.type, this.fn, this.options);
    }

    remove() {
        this.target?.removeEventListener(this.type, this.fn, this.options);
    }
}

class ListenerMap {
    private map: Map<number, Listener> = new Map<number, Listener>();
    private autoIncrement = 0;

    add(
        target: HTMLElement,
        type: string,
        cb: EventListenerOrEventListenerObject,
        options?: boolean | AddEventListenerOptions,
    ): number {
        this.map.set(
            this.autoIncrement,
            new Listener(target, type, cb, options),
        );

        this.autoIncrement += 1;

        return this.autoIncrement;
    }

    remove(id: number) {
        const listener = this.map.get(id);
        if (listener) {
            listener.remove();
            this.map.delete(id);
        }
    }

    removeAll() {
        this.map.forEach((listener) => {
            listener.remove();
        });
        this.map.clear();
    }
}

export class ContactForm {
    private form: HTMLFormElement;
    private sendButton: HTMLInputElement;
    private fields: Record<string, HTMLInputElement> = {};
    private hints: {
        error: HTMLElement,
        success: HTMLElement,
        required: HTMLElement
    };
    private listeners: ListenerMap = new ListenerMap();
    private data: Record<string, any> = {};

    private initForm() {
        this.form = document.querySelector('#contact-form');
    }

    private initField(name: string, selector: string) {
        this.data[name] = '';
        this.fields[name] = document.querySelector(selector);
    }

    private initFields() {
        this.initField('name', '#full-name');
        this.initField('company', '#company');
        this.initField('email', '#email');
        this.initField('phone', '#phone');
        this.initField('message', '#message');
        this.initField('budget', '#budget');
        this.initField('terms', '#confirm-terms');
    }

    private initListener() {
        Object.entries(this.fields).forEach(([name, field]) => {
            this.listeners.add(field, 'input', this.onFieldChange.bind(this, name, field));
        });
        this.listeners.add(this.form, 'input', this.onFormChange.bind(this));
        this.listeners.add(this.sendButton, 'click', this.submit.bind(this));
    }

    init() {
        this.hints = {
            error: document.querySelector('#hint-error') as HTMLElement,
            success: document.querySelector('#hint-success') as HTMLElement,
            required: document.querySelector('#hint-required') as HTMLElement
        };

        this.sendButton = document.querySelector('#contact-btn-send');

        this.initForm();
        this.initFields();
        this.initListener();

    }

    destroy() {
        this.listeners.removeAll();
    }

    /*
     *handle field change
     */
    private onFieldChange(name: string, field: HTMLInputElement) {
        this.data[name] = field.value;
    }

    /*
     *handle form change
     */
    private onFormChange() {
        this.sendButton.disabled = !this.isValid;
        this.isValid && this.hideHint(this.hints.required);
    }

    /*
     *check if all fields are valid
     */
    private get isValid() {
        return Object.values(this.fields).reduce((valid, field) => {
            valid = field.validity.valid ? valid : false;
            return valid;
        }, true);
    }

    private showHint(element: HTMLElement) {
        element.classList.remove('contact-form-hidden');
    }

    private hideHint(element: HTMLElement) {
        element.classList.add('contact-form-hidden');
    }

    /*
     *submit the form
     */
    private async submit(event: Event) {
        event.preventDefault();

        if (!this.isValid) {
            this.showHint(this.hints.required);
            return;
        }
        else {
            this.hideHint(this.hints.required);
        }

        await this.send();
    }

    /*
     *send message
     */
    private async send() {
        this.sendButton.disabled = true;

        try {
            const response = await fetch('/api/contact/form', { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(this.data)
            });

            console.log(response);

            if (!response.ok) {
                console.error(`HTTP error! Status: ${response.status}`);
                this.showHint(this.hints.error);
            }
            else {
                this.showHint(this.hints.success);
            }
        }
        catch (error) {
            console.error(error);
            this.showHint(this.hints.error);
        }

        this.form.reset();
        this.sendButton.disabled = false;
    }
}
