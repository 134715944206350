import barba from '@barba/core';
import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

import vertex from '../shaders/vertex.glsl';
import fragment from '../shaders/fragment.sphere.glsl'
import {Sketch} from './three/sketch';
import {Shader} from './three/shader';
import {Cursor} from './components/custom-cursor';
import {ThemeSwitcher} from './components/theme-switcher';
import {pageTransitionIn, pageTransitionOut} from './helper';
import {darkTheme, lightTheme} from './themes';
import {IntersectionAnimator} from './components/intersection-animator';
import {TextReveal} from './components/text-reveal.animation';
import {LineDraw} from './components/line-draw.animation';
import {OpacityReveal} from './components/opacity.animation';
import {ElasticLine} from './components/elastic-line';
import {Navigation} from './components/navigation';
import {TextButton} from './components/letter-button.animation';
import {IotAnimation} from './components/iot.animation';
import {ServiceList} from './components/service-list';
import {ContactForm} from './components/contact-form';
import {Magnetic} from './components/magnetic';

export const setupGsap = () => {
    gsap.registerPlugin(CustomEase);
};

export const setupThemeSwitcher = () => {
    barba.hooks.beforeOnce(() => {
        const themeSwitcher = new ThemeSwitcher('#theme', 'dark');

        themeSwitcher.addTheme('light', lightTheme);
        themeSwitcher.addTheme('dark', darkTheme);
    });
};

export const setupCursor = () => {
    const trail = document.querySelector('.custom-trail') as HTMLElement;
    const cursor = new Cursor(undefined, trail, 0.3);

    barba.hooks.beforeEnter(() => {
        cursor.init();
    });
    barba.hooks.afterLeave(() => {
        cursor.resetActive();
    });
};

export const logLifecycle = () => {
    barba.hooks.beforeOnce(() => {
        console.log('beforeOnce');
    });
    barba.hooks.once(() => {
        console.log('once');
    });
    barba.hooks.afterOnce(() => {
        console.log('afterOnce');
    });
    barba.hooks.before(() => {
        console.log('before');
    });
    barba.hooks.beforeLeave(() => {
        console.log('beforeLeave');
    });
    barba.hooks.leave(() => {
        console.log('leave');
    });
    barba.hooks.afterLeave(() => {
        console.log('afterLeave');
    });
    barba.hooks.beforeEnter(() => {
        console.log('beforeEnter');
    });
    barba.hooks.enter(() => {
        console.log('enter');
    });
    barba.hooks.afterEnter(() => {
        console.log('afterEnter');
    });
    barba.hooks.after(() => {
        console.log('after');
    });
};

export const setupAnimator = () => {
    const animator = new IntersectionAnimator(
        {
            selector: '[data-reveal]',
            create: (element, index) => new TextReveal(element, index, { animateOut: false })
        },
        {
            selector: '[data-line]',
            create: (element, index) => new LineDraw(element, index)
        },
        {
            selector: '[data-opacity]',
            create: (element, index) => new OpacityReveal(element, index)
        }
    );

    barba.hooks.beforeEnter(() => {
        document.fonts.addEventListener('loadingdone', () => {
            animator.init();
        });
    });

    barba.hooks.afterLeave(() => {
        animator.destroy();
    });
};

export const setupTextButtons = () => {
    barba.hooks.beforeEnter(() => {
        const buttons = Array.from(document.querySelectorAll('.text-button'));
        buttons.forEach((button) => new TextButton(button));
    });
};

export const setupScrollPosition = () => {
    if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
    }
    barba.hooks.afterLeave(() => {
        window.scrollX = 0;
        window.scrollY = 0;
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    });
};

export const setupElasticLines = () => {
    let lines: ElasticLine[] = [];

    const load = () => {
        setTimeout(() => {
            lines = Array.from(document.querySelectorAll('[data-elastic]'))
                .map((element) => {
                    const line = new ElasticLine(element);
                    line.init();
                    return line;
                });
        }, 0);
    };

    barba.hooks.afterOnce(load);
    barba.hooks.after(load);

    barba.hooks.afterLeave(() => {
        lines.forEach((line) => {
            line.destroy();
        });
        lines = [];
    });
};

export const setupNavigation = () => {
    const nav = new Navigation();

    barba.hooks.afterLeave(() => {
        nav.setClose();
    });

    barba.hooks.beforeOnce(() => {
        nav.init();
    });
};

export const setupScrollButtons = () => {
    let scrollDownBtn: HTMLElement, scrollUpBtn: HTMLElement;

    const scrollDown = () => {
        window.scrollTo({
            top: window.innerHeight,
            left: 0,
            behavior: 'smooth'
        });
    };
    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    const loader = () => {
        scrollDownBtn = document.querySelector('[data-scroll-down]');
        scrollUpBtn = document.querySelector('[data-scroll-up]');
        scrollDownBtn?.addEventListener('click', scrollDown);
        scrollUpBtn?.addEventListener('click', scrollUp);
    };

    barba.hooks.afterOnce(loader);
    barba.hooks.after(loader);

    barba.hooks.afterLeave(() => {
        if (scrollDownBtn) {
            scrollDownBtn.removeEventListener('click', scrollDown);
        }
        if (scrollUpBtn) {
            scrollUpBtn.removeEventListener('click', scrollUp);
        }
    });
};

export const setupSphere = () => {
    let shader: Sketch;
    let observer: IntersectionObserver;

    const loader = (data) => {
        if (data.next.namespace === 'home' && !shader) {
            const header = document.querySelector('.home-header-sphere');
            shader = new Shader(header, vertex, fragment);

            observer = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        shader.play();
                    }
                    else {
                        shader.pause();
                    }
                });
            }, {
                root: null,
                rootMargin: '0% 0% 0% 0%',
                threshold: 0.0
            }); 
            
            observer.observe(header);

            shader.addEventListener(Sketch.events.READY, () => shader.play());

            shader.init();
        }
    };

    barba.hooks.afterOnce(loader);
    barba.hooks.after(loader);

    barba.hooks.afterLeave((data) => {
        if (data.current.namespace === 'home' && shader) {
            observer.disconnect();
            shader.destroy(); 
            shader = undefined;
        }
    });
};

export const setupServiceList = () => {
    let serviceList: ServiceList;

    const loader = (data) => {
        if (
            (
                data.next.namespace === 'engineering' ||
                data.next.namespace === 'development' ||
                data.next.namespace === 'home' ||
                data.next.namespace === 'agile'
            ) && !serviceList
        ) {
            setTimeout(() => {
                serviceList = new ServiceList();
                serviceList.init();
            }, 50);
        }
    };

    barba.hooks.afterOnce(loader);
    barba.hooks.after(loader);

    barba.hooks.afterLeave(() => {
        if (serviceList) {
            serviceList.destroy();
            serviceList = undefined;
        }
    });
};

export const setupIotAnimation = () => {
    let animation: IotAnimation;
    
    barba.hooks.beforeEnter((data) => {
        if (data.next.namespace === 'iot') {
            setTimeout(() => {
                animation = new IotAnimation();
                animation.init();
            });
        }
    });

    barba.hooks.afterLeave((data) => {
        if (data.current.namespace === 'iot' && animation) {
            animation = undefined;
        }
    });
};

export const setupContactForm = () => {
    let contactForm: ContactForm;

    barba.hooks.beforeEnter((data) => {
        if (data.next.namespace === 'contact' && !contactForm) {
            contactForm = new ContactForm();
            contactForm.init();
        }
    });
    
    barba.hooks.afterLeave((data) => {
        if (data.current.namespace === 'contact' && contactForm) {
            contactForm.destroy();
            contactForm = undefined;
        }
    });
};

export const setupMagneticElements = () => {
    let elements: Magnetic[] = [];

    const loader = () => {
        elements = Array.from(document.querySelectorAll('[data-magnetic]'))
            .map((element) => {
                const magnetic = new Magnetic(element, element);
                magnetic.init();
                return magnetic;
            });
    };

    barba.hooks.afterOnce(loader);
    barba.hooks.after(loader);

    barba.hooks.afterLeave(() => {
        elements.forEach((element) => {
            element.destroy();
        });
        elements = [];
    });
};

export const setupContactBanner = () => {
    let magnetic: Magnetic;

    const loader = () => {
        if (!magnetic) {
            const button = document.querySelector('.contact-banner-button');
            const buttonInner = button.querySelector('.contact-banner-button-inner');
            const buttonBackground = button.querySelector('.contact-banner-button-background');

            if (!button || !buttonInner || !buttonBackground) {
                return;
            }

            setTimeout(() => {
                const magnetic = new Magnetic(button, buttonInner, buttonBackground);
                magnetic.init();
                magnetic.startRender();
            }, 0);
        }
    };

    barba.hooks.afterOnce(loader);
    barba.hooks.after(loader);

    barba.hooks.afterLeave(() => {
        if (magnetic) {
            magnetic.stopRender();
            magnetic.destroy();
            magnetic = undefined;
        }
    });
};

export const setupBarba = () => {
    barba.init({
        transitions: [
            {
                name: 'default-transition',
                leave() {
                    return pageTransitionIn();
                },
                enter() {
                    pageTransitionOut();
                },
                once() {
                    pageTransitionOut();
                }
            },
            {
                name: 'self',
                leave() {
                    return pageTransitionIn();
                },
                enter() {
                    pageTransitionOut();
                }
            }
        ]
    });
};
