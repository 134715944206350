export interface AnimationTarget {
    id: string;
    element: HTMLElement;
    inView: boolean;
    in(): void;
    out(): void;
    setIn(): void;
    setOut(): void;
}

export type AnimationTargetFactory<TARGET extends AnimationTarget = AnimationTarget> = {
    selector: string,
    create: (element: HTMLElement, index: number) => TARGET
};

export class IntersectionAnimator<TARGET extends AnimationTarget> {
    private factories: AnimationTargetFactory[];
    private elements: Record<string, TARGET> = {};
    private observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                this.handleInView(entry.target as HTMLElement);
            }
            else {
                this.handleOutView(entry.target as HTMLElement);
            }
        });
    }, {
        root: null,
        rootMargin: '0% 0% -20% 0%',
        threshold: 0.25
    });

    constructor(...factories: AnimationTargetFactory[]) {
        this.factories = factories;
    }

    init() {
        this.elements = this.factories.reduce((elements, factory) => {
            const htmlElements = Array.from(document.querySelectorAll(factory.selector)) as HTMLElement[];

            const targets = htmlElements.map((htmlElement, index) => factory.create(htmlElement, index));

            targets.forEach((target) => {
                elements[target.id] = target;
                this.observer.observe(target.element);
                target.setOut();
            });

            return elements;
        }, {});
    }

    in() {
        Object.values(this.elements).forEach((el) => el.in());
    }

    out() {
        Object.values(this.elements).forEach((el) => el.out());
    }

    setIn() {
        Object.values(this.elements).forEach((el) => el.setIn());
    }

    setOut() {
        Object.values(this.elements).forEach((el) => el.setOut());
    }

    destroy() {
        this.observer.disconnect();
    }

    private handleInView(element: HTMLElement) {
        this.elements[element.id]?.in();
    }

    private handleOutView(element: HTMLElement) {
        this.elements[element.id]?.out();
    }
}
