import { Animatable } from './animatable';

export type AnimationState = 'running' | 'stopped';

export type AnimationTimeline = 'sequential' | 'parallel';

export type AnimationOptions = {
    id: string,
    delay?: number
};

export const isAnimationOptions = (options: Animatable | AnimationOptions): options is AnimationOptions => 'id' in options;
